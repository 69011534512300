// Importa React y otros módulos necesarios
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Familia.css";
import Cart from "../Carrito/Carrito";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Familias = () => {
  const [familias, setFamilias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [clientDialogOpen, setClientDialogOpen] = useState(false);
  const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);
  const [editClientDialogOpen, setEditClientDialogOpen] = useState(false);
  const [extrasDialogOpen, setExtrasDialogOpen] = useState(false);
  const [newExtraDialogOpen, setNewExtraDialogOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [extras, setExtras] = useState([]);
  const [productToBeAdded, setProductToBeAdded] = useState(null);
  const [menuDiarioDialogOpen, setMenuDiarioDialogOpen] = useState(false);
  const [menusDiarios, setMenusDiarios] = useState([]);
  const [selectedMenuDiario, setSelectedMenuDiario] = useState({
    primero: "",
    segundo: "",
    postre: "",
  });

  const [newClient, setNewClient] = useState({
    nif: "",
    nombre: "",
    personaContacto: "",
    telefono: "",
    datosAdicionales: "",
  });

  const [editClient, setEditClient] = useState({
    id: "",
    nif: "",
    nombre: "",
    personaContacto: "",
    telefono: "",
    datosAdicionales: "",
  });

  const [newExtra, setNewExtra] = useState({
    nombre: "",
    precio: "",
  });

  const [errors, setErrors] = useState({
    nombre: false,
    telefono: false,
    nombreExtra: false,
    precioExtra: false,
  });

  useEffect(() => {
    axios
      .get("https://backlabranza.rvstudios.es/api/familias")
      .then((response) => {
        setFamilias(response.data);
      })
      .catch((error) => console.error("Error fetching familias:", error));

    axios
      .get("https://backlabranza.rvstudios.es/api/clientes")
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => console.error("Error fetching clients:", error));

    axios
      .get("https://backlabranza.rvstudios.es/api/extras")
      .then((response) => {
        setExtras(response.data);
      })
      .catch((error) => console.error("Error fetching extras:", error));

    axios
      .get("https://backlabranza.rvstudios.es/api/menus")
      .then((response) => {
        setMenusDiarios(response.data);
      })
      .catch((error) => console.error("Error fetching menus diarios:", error));
  }, []);

  const handleAddToCart = (producto) => {
    // Si no hay cliente seleccionado y es el primer ítem, solicitar seleccionar cliente
    if (!selectedClient && cartItems.length === 0) {
      setProductToBeAdded(producto);
      setClientDialogOpen(true);
    } else {
      // Si hay cliente, procedemos a seleccionar extras
      setProductToBeAdded(producto);
      setExtrasDialogOpen(true);
    }
  };

  const handleAddMenuDiarioToCart = () => {
    if (
      !selectedMenuDiario.primero ||
      !selectedMenuDiario.segundo ||
      !selectedMenuDiario.postre
    ) {
      toast.error("Por favor, seleccione un primero, un segundo y un postre.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const menu = menusDiarios.find(
      (menu) =>
        menu.primeros.includes(selectedMenuDiario.primero) &&
        menu.segundos.includes(selectedMenuDiario.segundo) &&
        menu.postres.includes(selectedMenuDiario.postre)
    );

    if (menu) {
      setCartItems([
        ...cartItems,
        {
          ...menu,
          cantidad: 1,
          primeroSeleccionado: selectedMenuDiario.primero,
          segundoSeleccionado: selectedMenuDiario.segundo,
          postreSeleccionado: selectedMenuDiario.postre,
          productIdWithExtras: `menu:${menu.id}`,
          precioTotal: menu.precio,
        },
      ]);
      setMenuDiarioDialogOpen(false);
      toast.success("Menú diario añadido con éxito", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const addProductToCart = () => {
    const productIdWithExtras = `${productToBeAdded.id}:${selectedExtras
      .map((e) => e.id)
      .sort()
      .join(",")}`;
    const existingItem = cartItems.find(
      (item) => item.productIdWithExtras === productIdWithExtras
    );

    if (existingItem) {
      setCartItems(
        cartItems.map((item) =>
          item.productIdWithExtras === productIdWithExtras
            ? { ...item, cantidad: item.cantidad + 1 }
            : item
        )
      );
    } else {
      // Aquí se crea un nuevo item sin modificar el precio del producto original
      setCartItems([
        ...cartItems,
        {
          ...productToBeAdded,
          cantidad: 1,
          extras: selectedExtras,
          precioBase: productToBeAdded.precio, // Se almacena el precio base por separado
          precioTotal:
            productToBeAdded.precio +
            selectedExtras.reduce((sum, extra) => sum + extra.precio, 0),
          productIdWithExtras,
        },
      ]);
    }

    setProductToBeAdded(null);
    setSelectedExtras([]);
    setExtrasDialogOpen(false);
  };

  const handleClientSelection = (clientId) => {
    setSelectedClient(clientId);
  };

  const handleExtraSelection = () => {
    addProductToCart(productToBeAdded);
  };

  const handleRemoveFromCart = (id) => {
    setCartItems(cartItems.filter((item) => item.productIdWithExtras !== id));
  };

  const handleUpdateQuantity = (productIdWithExtras, delta) => {
    const newCartItems = cartItems
      .map((item) => {
        if (item.productIdWithExtras === productIdWithExtras) {
          // Asegúrate de que la cantidad no sea negativa
          const newQuantity = Math.max(0, item.cantidad + delta);
          return { ...item, cantidad: newQuantity };
        }
        return item;
      })
      .filter((item) => item.cantidad > 0); // Elimina items con cantidad 0

    setCartItems(newCartItems);
  };

  const handleClearCart = () => {
    setCartItems([]);
    setSelectedClient(""); // Reset client selection
  };

  const cargarProductos = (familiaId) => {
    axios
      .get(
        `https://backlabranza.rvstudios.es/api/familias/${familiaId}/productos`
      )
      .then((response) => {
        setProductos(response.data);
        setCurrentPage(1);
      })
      .catch((error) => {
        console.error("Error fetching productos:", error);
      });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const obtenerClaseFamilia = (nombreFamilia) => {
    switch (nombreFamilia.toLowerCase()) {
      case "hamburguesas":
        return "hamburguesas";
      case "raciones":
        return "raciones";
      case "bocadillos y montados":
        return "bocadillos y montados";
      case "sandwich y croissant":
        return "sandwich y croissant";
      case "sartenes":
        return "sartenes";
      case "tradicionales":
        return "tradicionales";
      case "ibericos":
        return "ibericos";
      case "de la mar":
        return "mar";
      case "de la tierra":
        return "tierra";
      case "bebidas y refrescos":
        return "bebidas";
      default:
        return "";
    }
  };

  const filteredProductos = productos.filter((producto) =>
    producto.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProductos.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNewClientChange = (e) => {
    const { name, value } = e.target;
    setNewClient({ ...newClient, [name]: value });
  };

  const handleEditClientChange = (e) => {
    const { name, value } = e.target;
    setEditClient({ ...editClient, [name]: value });
  };

  const handleNewExtraChange = (e) => {
    const { name, value } = e.target;
    setNewExtra({ ...newExtra, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {
      nombre: newClient.nombre.trim() === "",
      telefono: newClient.telefono.trim() === "",
      nombreExtra: newExtra.nombre.trim() === "",
      precioExtra: newExtra.precio.trim() === "" || isNaN(newExtra.precio),
    };
    setErrors(newErrors);
    return (
      !newErrors.nombre &&
      !newErrors.telefono &&
      !newErrors.nombreExtra &&
      !newErrors.precioExtra
    );
  };

  const validateFormExtras = () => {
    const newErrors = {
      nombreExtra: newExtra.nombre.trim() === "",
      precioExtra: newExtra.precio.trim() === "" || isNaN(newExtra.precio),
    };
    setErrors(newErrors);
    return !newErrors.nombreExtra && !newErrors.precioExtra;
  };

  const handleExtraChange = (event, extra) => {
    const selectedIndex = selectedExtras.indexOf(extra);
    let newSelectedExtras = [];

    if (selectedIndex === -1) {
      newSelectedExtras = newSelectedExtras.concat(selectedExtras, extra);
    } else if (selectedIndex >= 0) {
      newSelectedExtras = newSelectedExtras.concat(
        selectedExtras.slice(0, selectedIndex),
        selectedExtras.slice(selectedIndex + 1)
      );
    }

    setSelectedExtras(newSelectedExtras);
  };

  const handleNewClientSubmit = () => {
    if (!validateForm()) {
      return;
    }

    axios
      .post("https://backlabranza.rvstudios.es/api/clientes", newClient)
      .then((response) => {
        setClients([...clients, response.data]);
        setSelectedClient(response.data.id);
        setNewClientDialogOpen(false);
        setClientDialogOpen(false);
        toast.success("Cliente agregado con éxito", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (productToBeAdded) {
          setExtrasDialogOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error creating new client:", error);
      });
  };

  const handleEditClientSubmit = () => {
    axios
      .put(
        `https://backlabranza.rvstudios.es/api/clientes/${editClient.id}`,
        editClient
      )
      .then((response) => {
        setClients(
          clients.map((client) =>
            client.id === response.data.id ? response.data : client
          )
        );
        setEditClientDialogOpen(false);
        toast.success("Cliente actualizado con éxito", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Error updating client:", error);
      });
  };

  const handleNewExtraSubmit = () => {
    if (!validateFormExtras()) {
      return;
    }

    axios
      .post("https://backlabranza.rvstudios.es/api/extras", newExtra)
      .then((response) => {
        setExtras([...extras, response.data]);
        setSelectedExtras([...selectedExtras, response.data]);
        setNewExtraDialogOpen(false);
        toast.success("Extra agregado con éxito", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Error creating new extra:", error);
      });
  };

  return (
    <div className="main-container">
      <ToastContainer />
      {clientDialogOpen && (
        <Dialog
          open={clientDialogOpen}
          onClose={() => setClientDialogOpen(false)}
        >
          <DialogTitle>Seleccione un Cliente</DialogTitle>
          <DialogContent className="cuadroDialogo">
            <Select
              value={selectedClient}
              onChange={(e) => handleClientSelection(e.target.value)}
              fullWidth
              className="selectClientes"
            >
              {clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.nombre}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <div>
              <Button onClick={() => setNewClientDialogOpen(true)}>
                Nuevo cliente
              </Button>
              <Button
                onClick={() => {
                  const client = clients.find((c) => c.id === selectedClient);
                  setEditClient(client);
                  setEditClientDialogOpen(true);
                }}
                disabled={!selectedClient}
              >
                Editar cliente
              </Button>
              <Button
                onClick={() => {
                  setClientDialogOpen(false);
                  if (productToBeAdded) {
                    setExtrasDialogOpen(true);
                  }
                }}
              >
                Aceptar
              </Button>
            </div>
            <Button onClick={() => setClientDialogOpen(false)}>Cancelar</Button>
          </DialogActions>
        </Dialog>
      )}
      {editClientDialogOpen && (
        <Dialog
          open={editClientDialogOpen}
          onClose={() => setEditClientDialogOpen(false)}
        >
          <DialogTitle>Editar Cliente</DialogTitle>
          <DialogContent className="dialogoModal">
            <TextField
              label="NIF"
              name="nif"
              value={editClient.nif}
              onChange={handleEditClientChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Nombre"
              name="nombre"
              value={editClient.nombre}
              onChange={handleEditClientChange}
              fullWidth
              margin="dense"
              error={errors.nombre}
              helperText={errors.nombre ? "El nombre es obligatorio" : ""}
            />
            <TextField
              label="Persona de Contacto"
              name="personaContacto"
              value={editClient.personaContacto}
              onChange={handleEditClientChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Teléfono"
              name="telefono"
              value={editClient.telefono}
              onChange={handleEditClientChange}
              fullWidth
              margin="dense"
              error={errors.telefono}
              helperText={errors.telefono ? "El teléfono es obligatorio" : ""}
            />
            <TextField
              label="Datos Adicionales / Dirección de entrega"
              name="datosAdicionales"
              value={editClient.datosAdicionales}
              onChange={handleEditClientChange}
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditClientDialogOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={handleEditClientSubmit}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      )}
      {newClientDialogOpen && (
        <Dialog
          open={newClientDialogOpen}
          onClose={() => setNewClientDialogOpen(false)}
        >
          <DialogTitle>Nuevo Cliente</DialogTitle>
          <DialogContent className="dialogoModal">
            <TextField
              label="NIF"
              name="nif"
              value={newClient.nif}
              onChange={handleNewClientChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Nombre"
              name="nombre"
              value={newClient.nombre}
              onChange={handleNewClientChange}
              fullWidth
              margin="dense"
              error={errors.nombre}
              helperText={errors.nombre ? "El nombre es obligatorio" : ""}
            />
            <TextField
              label="Persona de Contacto"
              name="personaContacto"
              value={newClient.personaContacto}
              onChange={handleNewClientChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Teléfono"
              name="telefono"
              value={newClient.telefono}
              onChange={handleNewClientChange}
              fullWidth
              margin="dense"
              error={errors.telefono}
              helperText={errors.telefono ? "El teléfono es obligatorio" : ""}
            />
            <TextField
              label="Datos Adicionales"
              name="datosAdicionales"
              value={newClient.datosAdicionales}
              onChange={handleNewClientChange}
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNewClientDialogOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={handleNewClientSubmit}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      )}
      {extrasDialogOpen && (
        <Dialog
          open={extrasDialogOpen}
          onClose={() => setExtrasDialogOpen(false)}
        >
          <DialogTitle>Extras y bebidas</DialogTitle>
          <DialogContent className="cuadroDialogo">
            <TableContainer component={Paper}>
              <Table aria-label="tabla de extras">
                <TableHead>
                  <TableRow>
                    <TableCell>Extra</TableCell>
                    <TableCell align="right">Precio</TableCell>
                    <TableCell align="right">Seleccionar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extras.map((extra) => (
                    <TableRow
                      key={extra.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {extra.nombre}
                      </TableCell>
                      <TableCell align="right">
                        {extra.precio.toFixed(2)} €
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={selectedExtras.indexOf(extra) > -1}
                          onChange={(event) => handleExtraChange(event, extra)}
                          inputProps={{ "aria-label": "select extra" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNewExtraDialogOpen(true)}>
              Nuevo extra
            </Button>
            <Button onClick={() => setExtrasDialogOpen(false)}>Cancelar</Button>
            <Button onClick={handleExtraSelection}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      )}
      {newExtraDialogOpen && (
        <Dialog
          open={newExtraDialogOpen}
          onClose={() => setNewExtraDialogOpen(false)}
        >
          <DialogTitle>Nuevo Extra</DialogTitle>
          <DialogContent className="dialogoModal">
            <TextField
              label="Nombre"
              name="nombre"
              value={newExtra.nombre}
              onChange={handleNewExtraChange}
              fullWidth
              margin="dense"
              error={errors.nombreExtra}
              helperText={errors.nombreExtra ? "El nombre es obligatorio" : ""}
            />
            <TextField
              label="Precio"
              name="precio"
              value={newExtra.precio}
              onChange={handleNewExtraChange}
              fullWidth
              margin="dense"
              error={errors.precioExtra}
              helperText={
                errors.precioExtra
                  ? "El precio es obligatorio y debe ser un número"
                  : ""
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNewExtraDialogOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={handleNewExtraSubmit}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      )}
      {menuDiarioDialogOpen && (
        <Dialog
          open={menuDiarioDialogOpen}
          onClose={() => setMenuDiarioDialogOpen(false)}
        >
          <DialogTitle>Seleccione un Menú Diario</DialogTitle>
          <DialogContent>
            <Select
              value={selectedMenuDiario.primero}
              onChange={(e) =>
                setSelectedMenuDiario({
                  ...selectedMenuDiario,
                  primero: e.target.value,
                })
              }
              displayEmpty
              fullWidth
              margin="dense"
            >
              <MenuItem value="" disabled>
                Seleccione un primero
              </MenuItem>
              {menusDiarios
                .flatMap((menu) => menu.primeros)
                .map((primero, index) => (
                  <MenuItem key={index} value={primero}>
                    {primero}
                  </MenuItem>
                ))}
            </Select>
            <Select
              value={selectedMenuDiario.segundo}
              onChange={(e) =>
                setSelectedMenuDiario({
                  ...selectedMenuDiario,
                  segundo: e.target.value,
                })
              }
              displayEmpty
              fullWidth
              margin="dense"
            >
              <MenuItem value="" disabled>
                Seleccione un segundo
              </MenuItem>
              {menusDiarios
                .flatMap((menu) => menu.segundos)
                .map((segundo, index) => (
                  <MenuItem key={index} value={segundo}>
                    {segundo}
                  </MenuItem>
                ))}
            </Select>
            <Select
              value={selectedMenuDiario.postre}
              onChange={(e) =>
                setSelectedMenuDiario({
                  ...selectedMenuDiario,
                  postre: e.target.value,
                })
              }
              displayEmpty
              fullWidth
              margin="dense"
            >
              <MenuItem value="" disabled>
                Seleccione un postre
              </MenuItem>
              {menusDiarios
                .flatMap((menu) => menu.postres)
                .map((postre, index) => (
                  <MenuItem key={index} value={postre}>
                    {postre}
                  </MenuItem>
                ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setMenuDiarioDialogOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={handleAddMenuDiarioToCart}>Añadir</Button>
          </DialogActions>
        </Dialog>
      )}
      <Cart
        cartItems={cartItems}
        onRemove={handleRemoveFromCart}
        onUpdateQuantity={handleUpdateQuantity}
        onClearCart={handleClearCart}
        clientName={
          clients.find((client) => client.id === selectedClient)?.nombre ||
          "No seleccionado"
        }
        contactPerson={
          clients.find((client) => client.id === selectedClient)
            ?.personaContacto || ""
        }
        clientData={
          clients.find((client) => client.id === selectedClient)
            ?.datosAdicionales || ""
        }
      />

      <div className="familias-container">
        {familias.map((familia) => (
          <div
            key={familia.id}
            className={`familia-card ${obtenerClaseFamilia(familia.nombre)}`}
            onClick={() => cargarProductos(familia.id)}
          >
            <h2>{familia.nombre}</h2>
            <p>{familia.descripcion}</p>
          </div>
        ))}
        <div
          className="familia-card menu-diario-card"
          onClick={() => {
            if (!selectedClient) {
              setClientDialogOpen(true);
            } else {
              setMenuDiarioDialogOpen(true);
            }
          }}
        >
          <h2>Menú Diario</h2>
        </div>
      </div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Buscar productos..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="productos-container">
        {currentItems.map((producto) => (
          <div
            key={producto.id}
            className={`producto-card ${obtenerClaseFamilia(
              producto.familia.nombre
            )}`}
            onClick={() => handleAddToCart(producto)}
          >
            <h2>
              {producto.nombre}- {producto.precio} €
            </h2>
            <p>{producto.descripcion}</p>

            <button
              className="botonAgregar"
              onClick={() => handleAddToCart(producto)}
            >
              Añadir
            </button>
          </div>
        ))}
      </div>
      <div className="pagination">
        {[
          ...Array(Math.ceil(filteredProductos.length / itemsPerPage)).keys(),
        ].map((number) => (
          <button key={number + 1} onClick={() => handlePageChange(number + 1)}>
            {number + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Familias;
