import React, { useState } from "react";
import "./Cart.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { generatePdf } from "../PDF/generatePdf";

const Cart = ({
  cartItems,
  onRemove,
  onUpdateQuantity,
  onClearCart,
  clientName,
  contactPerson,  // Añadir este prop
  clientData, // Añadir este prop
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (cartItems.length === 0) return null; // No renderiza el carrito si está vacío

  const totalItems = cartItems.reduce(
    (total, item) => total + item.cantidad,
    0
  );

  // Corregir el cálculo del precio total para que aplique correctamente los extras
  const totalPrice = cartItems.reduce((total, item) => {
    const extrasTotal = item.extras ? item.extras.reduce((sum, extra) => sum + extra.precio, 0) : 0;
    return total + (item.precioTotal * item.cantidad + extrasTotal * item.cantidad);
  }, 0);

  const toggleCart = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`cart-container ${isExpanded ? "expanded" : "collapsed"}`}>
      <button onClick={toggleCart} className="toggle-cart">
        <FontAwesomeIcon icon={isExpanded ? faChevronLeft : faChevronRight} />
        <span className="item-count">{totalItems}</span>
      </button>
      <h4>
        {clientName
          ? `${clientName} - Artículos: ${totalItems}`
          : "Seleccionar cliente"}{" "}
        || Precio Total: {totalPrice.toFixed(2)}€
      </h4>
      {isExpanded && (
        <>
          {cartItems.map((item) => (
            <div key={item.productIdWithExtras} className="cart-item">
              <div className="item-info">
                <h4>{item.nombre || "Menú Diario"}</h4>
                {item.nombre && <div>Precio Base: {item.precio.toFixed(2)} €</div>} {/* Muestra solo el precio base */}
                {item.extras ? (
                  <div className="extras-list">
                    {item.extras.length > 0 ? (
                      item.extras.map((extra) => (
                        <span key={extra.id} className="extra-name">
                          {extra.nombre} (+{extra.precio.toFixed(2)} €)
                        </span>
                      ))
                    ) : (
                      <span className="extra-name">Sin extras</span>
                    )}
                  </div>
                ) : (
                  <div>
                    <div>Primero: {item.primeroSeleccionado}</div>
                    <div>Segundo: {item.segundoSeleccionado}</div>
                    <div>Postre: {item.postreSeleccionado}</div>
                    <div>Precio: {item.precioTotal.toFixed(2)} €</div>
                  </div>
                )}
              </div>
              <div className="item-controls">
                <button
                  onClick={() => onUpdateQuantity(item.productIdWithExtras, -1)}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <span>{item.cantidad}</span>
                <button
                  onClick={() => onUpdateQuantity(item.productIdWithExtras, 1)}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
                <button onClick={() => onRemove(item.productIdWithExtras)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))}

          <div className="total">
            <button onClick={onClearCart} className="clear-cart">
              Borrar
            </button>
            <div>Total: {totalPrice.toFixed(2)}€</div>
            <button
              className="clear-cart"
              onClick={() => generatePdf(cartItems, onClearCart, clientName, contactPerson, clientData)}
            >
              Generar comanda
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
