import React from 'react';
import './Navbar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faChartSimple, faPrint, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar">
     
      <div className="navbar-logo">
        <p>La Labranza - Pedidos</p>
      </div>
     
      <div className="navbar-icons navbar-icons-right"> 
        
        <FontAwesomeIcon className="icon" icon={faChartSimple} style={{marginRight: "2rem", color:"#015fa7"}}/>
        <FontAwesomeIcon className="icon" icon={faPrint} style={{marginRight: "2rem", color:"#015fa7"}}/>
        <Link to="/ajustes">
          <FontAwesomeIcon className="icon" icon={faGear} style={{marginRight: "2rem", color: "#575c60"}} />
        </Link>
                <FontAwesomeIcon className="icon" icon={faCircleInfo} style={{marginRight: "2rem", color: "green"}}/>
      </div>
    </nav>
  );
}

export default Navbar;
