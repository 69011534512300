import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Familias from './components/Familias/Familias';
import Ajustes from './components/Ajustes/Ajustes'; // Asegúrate de tener este componente

const App = () => {
  return (
    <Router>
      <div className="app">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/familias" element={<Familias />} />
            <Route path="/ajustes" element={<Ajustes />} />
            <Route path="/" element={<Familias />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
