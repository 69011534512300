import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Ajustes.css';

const Ajustes = () => {
  const [data, setData] = useState({});
  const [page, setPage] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState(null);
  const [newItem, setNewItem] = useState({});
  const [currentSection, setCurrentSection] = useState('');
  const sections = ['clientes', 'productos', 'familias', 'extras', 'menus'];

  useEffect(() => {
    sections.forEach(section => {
      fetchData(section);
      setPage(prev => ({ ...prev, [section]: 0 }));
    });
  }, []);

  const fetchData = (section) => {
    axios.get(`https://backlabranza.rvstudios.es/api/${section}`)
      .then(response => {
        setData(prevData => ({
          ...prevData,
          [section]: response.data
        }));
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const handleChangePage = (section, event, newPage) => {
    setPage(prev => ({ ...prev, [section]: newPage }));
  };

  const handleChangeRowsPerPage = (section, event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(prev => ({ ...prev, [section]: 0 }));
  };

  const deleteItem = (section, id) => {
    if (window.confirm("¿Estás seguro de que quieres eliminar este elemento?")) {
      axios.delete(`https://backlabranza.rvstudios.es/api/${section}/${id}`)
        .then(() => {
          fetchData(section);
          toast.success('Elemento eliminado con éxito', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch(error => console.error('Error deleting item:', error));
    }
  };

  const openEditDialog = (section, item) => {
    setCurrentSection(section);
    setCurrentEditItem({ ...item });
    setEditDialogOpen(true);
  };

  const openAddDialog = (section) => {
    setCurrentSection(section);
    setNewItem({});
    setAddDialogOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCurrentEditItem(prev => ({ ...prev, [name]: value }));
  };

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setNewItem(prev => ({ ...prev, [name]: value }));
  };

  const handleArrayEditChange = (e, index, arrayName) => {
    const { value } = e.target;
    setCurrentEditItem(prev => {
      const newArray = [...prev[arrayName]];
      newArray[index] = value;
      return { ...prev, [arrayName]: newArray };
    });
  };

  const handleArrayAddChange = (e, index, arrayName) => {
    const { value } = e.target;
    setNewItem(prev => {
      const newArray = [...(prev[arrayName] || [])];
      newArray[index] = value;
      return { ...prev, [arrayName]: newArray };
    });
  };

  const handleSaveEdit = () => {
    axios.put(`https://backlabranza.rvstudios.es/api/${currentSection}/${currentEditItem.id}`, currentEditItem)
      .then(() => {
        setEditDialogOpen(false);
        fetchData(currentSection);
        toast.success('Elemento editado con éxito', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(error => console.error('Error updating item:', error));
  };

  const handleAddItem = () => {
    axios.post(`https://backlabranza.rvstudios.es/api/${currentSection}`, newItem)
      .then(() => {
        setAddDialogOpen(false);
        fetchData(currentSection);
        toast.success('Elemento añadido con éxito', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(error => console.error('Error adding item:', error));
  };

  return (
    <div className="ajustes-container">
      <ToastContainer />
      <Typography variant="h4" gutterBottom component="div" className="typography-header">
        Ajustes
      </Typography>
      {sections.map((section, index) => (
        <Accordion key={index} className="accordion-root">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section}-content`}
            id={`${section}-header`}
            className="accordion-summary"
          >
            <Typography>{section.charAt(0).toUpperCase() + section.slice(1)}</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            {section !== 'menus' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => openAddDialog(section)}
                style={{ marginBottom: '1rem' }}
              >
                Añadir
              </Button>
            )}
            <Table size="small" className="table-root">
              <TableHead className="table-head">
                <TableRow>
                  {data[section] && data[section][0] && Object.keys(data[section][0]).map((key) => (
                    <TableCell key={key} className="table-cell">{key}</TableCell>
                  ))}
                  <TableCell className="table-cell">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data[section] && data[section]
                  .slice(page[section] * rowsPerPage, page[section] * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow key={item.id}>
                      {Object.keys(item).map((key, idx) => (
                        <TableCell key={idx}>
                          {typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key]}
                        </TableCell>
                      ))}
                      <TableCell>
                        {section !== 'menus' && (
                          <Button color="error" onClick={() => deleteItem(section, item.id)}>
                            Eliminar
                          </Button>
                        )}
                        <Button color="primary" onClick={() => openEditDialog(section, item)}>
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={data[section] ? data[section].length : 0}
              rowsPerPage={rowsPerPage}
              page={page[section] || 0}
              onPageChange={(event, newPage) => handleChangePage(section, event, newPage)}
              onRowsPerPageChange={(event) => handleChangeRowsPerPage(section, event)}
            />
          </AccordionDetails>
        </Accordion>
      ))}

      {currentEditItem && (
        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Editar {currentSection.charAt(0).toUpperCase() + currentSection.slice(1)}</DialogTitle>
          <DialogContent>
            {Object.keys(currentEditItem).map((key) => (
              Array.isArray(currentEditItem[key]) ? (
                currentEditItem[key].map((item, index) => (
                  <TextField
                    key={`${key}-${index}`}
                    margin="dense"
                    label={`${key.charAt(0).toUpperCase() + key.slice(1)} ${index + 1}`}
                    name={key}
                    value={item}
                    onChange={(e) => handleArrayEditChange(e, index, key)}
                    fullWidth
                  />
                ))
              ) : (
                <TextField
                  key={key}
                  margin="dense"
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  name={key}
                  value={currentEditItem[key]}
                  onChange={handleEditChange}
                  fullWidth
                />
              )
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancelar</Button>
            <Button onClick={handleSaveEdit}>Guardar</Button>
          </DialogActions>
        </Dialog>
      )}

      {addDialogOpen && (
        <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
          <DialogTitle>Añadir {currentSection.charAt(0).toUpperCase() + currentSection.slice(1)}</DialogTitle>
          <DialogContent>
            {data[currentSection] && data[currentSection][0] && Object.keys(data[currentSection][0]).map((key) => (
              Array.isArray(data[currentSection][0][key]) ? (
                <TextField
                  key={key}
                  margin="dense"
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  name={key}
                  value={newItem[key] || ''}
                  onChange={(e) => handleArrayAddChange(e, 0, key)}
                  fullWidth
                />
              ) : (
                <TextField
                  key={key}
                  margin="dense"
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  name={key}
                  value={newItem[key] || ''}
                  onChange={handleAddChange}
                  fullWidth
                />
              )
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddDialogOpen(false)}>Cancelar</Button>
            <Button onClick={handleAddItem}>Añadir</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Ajustes;
